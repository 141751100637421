import React from 'react'
import styled from 'styled-components'

const Container = styled('div')({
  position: 'relative',
  height: 0,
  paddingBottom: '75%',
})

export default function StoryblokGoogleMap({ data }) {
  if (!data.embedUrl) return null
  return (
    <Container>
      <iframe
        src={data.embedUrl}
        width="100%"
        height="100%"
        allowFullScreen=""
        loading="lazy"
        style={{
          position: 'absolute',
          inset: 0,
          border: 0,
        }}
      />
    </Container>
  )
}
